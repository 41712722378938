<template>
  <b-row>
    <b-col md="3">
      <b-form-group
        label="Depo Kodu"
        label-for="wnumber"
      >
        <validation-provider
          #default="{ errors }"
          name="Depo Kodu"
          rules="required"
        >
          <b-form-input
            id="wnumber"
            v-model="dataItem.wnumber"
            placeholder="Depo Kodu"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <validation-provider
          #default="{ errors }"
          name="Başlık"
          rules="required"
        >
          <b-form-input
            id="title"
            v-model="dataItem.title"
            placeholder="Başlık"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'

export default {
  name: 'WarehouseForm',
  components: {
    ValidationProvider, BFormInput, BFormGroup, BRow, BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['warehouses/dataItem']
    },
  },
}
</script>
